import { LayoutHeader, LayoutHeaderProps } from '@graphcommerce/next-ui'

type LayoutHeaderVaessenProps = LayoutHeaderProps & { removeNegativeSpacing?: boolean }

export function LayoutHeaderVaessen(props: LayoutHeaderVaessenProps) {
  const { removeNegativeSpacing = false, sx = [] } = props

  return (
    <LayoutHeader
      {...props}
      sx={[
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            '&': {
              marginTop: removeNegativeSpacing ? 0 : `calc(${theme.appShell.headerHeightSm} * -1)`,
            },
          },
          '& .LayoutHeaderContent-bg': {
            borderTopLeftRadius: removeNegativeSpacing ? 0 : theme.shape.borderRadius * 3,
            borderTopRightRadius: removeNegativeSpacing ? 0 : theme.shape.borderRadius * 3,
          },

          [theme.breakpoints.up('md')]: {
            '&.floatingMd': {
              top: `calc(${theme.appShell.headerHeightMd} + calc((${theme.appShell.appBarHeightMd} - 46px) * -0.5))`,
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
