import { useRouter } from 'next/router'
import { useRef, useEffect } from 'react'

export function TrustPilotCarousel() {
  const { locale } = useRouter()
  const ref = useRef(null)

  useEffect(() => {
    if (globalThis.Trustpilot) {
      globalThis.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  if (!locale) return null

  const trustPilotLocale =
    locale === 'en' || locale === 'default' ? 'en-US' : `${locale}-${locale.toUpperCase()}`
  const trustPilotHref = `https://${
    locale === 'en' || locale === 'default' ? 'www' : locale
  }.trustpilot.com/review/vaessen-creative.com`
  const trustPilotReviewLocale = locale === 'default' ? 'en' : locale

  return (
    <div
      ref={ref}
      className='trustpilot-widget'
      data-locale={trustPilotLocale}
      data-template-id='53aa8912dec7e10d38f59f36'
      data-businessunit-id='5f2fd4a700bf7300017ea736'
      data-style-height='140px'
      data-style-width='100%'
      data-theme='light'
      data-stars='4,5'
      data-review-languages={trustPilotReviewLocale}
    >
      <a href={trustPilotHref} target='_blank' rel='noopener noreferrer'>
        Trustpilot
      </a>
    </div>
  )
}
