import { PageOptions } from '@graphcommerce/framer-next-pages'
import { HygraphPagesQuery, hygraphPageContent } from '@graphcommerce/graphcms-ui'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, MetaRobots, PageMeta, useStorefrontConfig } from '@graphcommerce/next-ui'
import { Container } from '@mui/material'
import Head from 'next/head'
import {
  LayoutHeaderVaessen,
  LayoutNavigation,
  LayoutNavigationProps,
  RowProduct,
  RowRenderer,
} from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { useCustomerProductPrices } from '../components/Product/useCustomerProductPrices'
import { TrustPilotCarousel } from '../components/TrustPilot/TrustPilotCarousel'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & {
  popularList: ProductListQuery
  favoritesList: ProductListQuery
}

type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, popularList, favoritesList } = props
  const page = pages?.[0]

  const favorites = useCustomerProductPrices(favoritesList.products)?.items
  const popular = useCustomerProductPrices(popularList.products)?.items
  const storeB2B = useStorefrontConfig().magentoStoreCode.includes('b2b')

  return (
    <>
      <Head>
        <link rel='alternate' href='https://www.vaessen-creative.com/' hrefLang='x-default' />
        <link rel='alternate' href='https://www.vaessen-creative.com/en' hrefLang='en' />
        <link rel='alternate' href='https://www.vaessen-creative.com/nl' hrefLang='nl' />
        <link rel='alternate' href='https://www.vaessen-creative.com/de' hrefLang='de' />
        <link rel='alternate' href='https://www.vaessen-creative.com/es' hrefLang='es' />
        <link rel='alternate' href='https://www.vaessen-creative.com/fr' hrefLang='fr' />
        <link rel='alternate' href='https://www.vaessen-creative.com/it' hrefLang='it' />
      </Head>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={
          storeB2B
            ? ['noindex', 'nofollow']
            : (page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined)
        }
        canonical='/'
      />

      <LayoutHeaderVaessen floatingMd floatingSm />

      {page && (
        <RowRenderer
          content={page.content}
          renderer={{
            RowProduct: (rowProps) => {
              const { identity } = rowProps

              if (identity === 'home-favorites')
                return <RowProduct {...rowProps} items={favorites} />
              if (identity === 'home-popular') return <RowProduct {...rowProps} items={popular} />
              return <> </>
            },
          }}
        />
      )}

      <Container maxWidth='xl' sx={(theme) => ({ marginBottom: theme.spacings.xl })}>
        <TrustPilotCarousel />
      </Container>
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  const popularList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTk5MA==' } } },
  })

  const favoritesList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTk5MQ==' } } },
  })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      popularList: (await popularList).data,
      favoritesList: (await favoritesList).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
